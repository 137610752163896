<template>
  <div class="container watermark-container">
    <van-nav-bar
      title="发起审批"
      left-text="返回"
      left-arrow
      @click-left="() => $router.back()"
    />
    <div style="margin: 10px 10px 0 0; border: 0px solid red">
      <form-create
        :rule="mergedRule"
        :option="option"
        @submit="onSubmit"
      ></form-create>
    </div>
    <!-- 在这里添加一个宽度约为 100px 的空白 -->
    <div style="height: 100px; background-color: transparent"></div>
  </div>
</template>

<script>
import api from '@/api/process'
import { MessageBox } from 'element-ui'
import addWatermark from '@/utils/watermark'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'process',

  data() {
    return {
      processTemplate: {},
      // 本身自带的表单
      rule: [],
      // 全局配置
      option: {},
      // 人员 表单
      rankRule: [],
      departmentList: [], // 部门数据
      personnelList: [], // 主送人员数据
      notifiedPersonList: [], // 抄送人员列表
      fileIdList: [], // 文件id数据
      mainGroupCount: 1, // 新增主送组计数器
      personnelOptions: {}, // 存储各主送组的人员选项
      selectPersonnelOptions: {}, // 主送组选中的人员数据
      departmentOptions: {}, // 主送组选中的部门数据
      remoreIndex: [], // 删除主送组的下标
    }
  },

  created() {
    let processTemplateId = this.$route.params.processTemplateId
    this.fetchData(processTemplateId)
    // 获取部门数据
    this.fetchDepartmentData()
  },
  computed: {
    mergedRule() {
      return [...this.rule, ...this.rankRule]
    },
    ...mapState({
      userInfo: (state) => state.userInfo, // 从 Vuex 中获取 userInfo
    }),
  },
  mounted() {
    // 如果 userInfo 没有值，则调用 Vuex action 获取用户信息
    if (!this.userInfo) {
      this.loadUserInfoAndAddWatermark()
    } else {
      // 如果已有 userInfo，直接添加水印
      this.addWatermark()
    }
  },
  methods: {
    // 获取用户信息缓存
    ...mapActions({
      loadUserInfo: 'fetchUserInfo', // 映射 fetchUserInfo 到组件的方法
    }),
    // 异步方法：先加载用户信息，再添加水印
    async loadUserInfoAndAddWatermark() {
      try {
        await this.loadUserInfo() // 等待 Vuex action 完成
        this.addWatermark() // 用户信息加载完成后添加水印
      } catch (error) {
        // console.error('加载用户信息失败:', error)
      }
    },
    addWatermark() {
      console.log('打印水印数据:' + this.userInfo)
      // 调用水印功能
      addWatermark({
        containerSelector: '.watermark-container', // 添加水印的目标容器
        text: '通用锁具 ' + this.userInfo.name, // 自定义水印文本
        font: '16px Arial',
        color: 'rgba(0, 0, 0, 0.15)',
        width: 200,
        height: 150,
        angle: -30,
      })
    },

    async fetchData(processTemplateId) {
      const response = await api.getProcessTemplate(processTemplateId)

      this.processTemplate = response.data
      // this.rule = JSON.parse(this.processTemplate.formProps)
      this.rule = this.parseValidator(
        JSON.parse(this.processTemplate.formProps),
        this.userInfo
      ) // 解析 rule
      this.option = JSON.parse(this.processTemplate.formOptions)

      // 等待文件上传的组件
      await this.addFileUploadField()

      if (this.processTemplate.templateType === 1) {
        await this.addDepartmentAndPersonnelFields()
      }
    },

    // 解析存储的函数字符串，转换为真正的 JavaScript 函数
    parseValidator(jsonData, userInfo) {
      jsonData.forEach((rule) => {
        if (rule.validate) {
          rule.validate.forEach((val) => {
            if (typeof val.validator === 'string') {
              try {
                // 直接替换为绑定 userInfo 的函数
                const func = new Function(
                  'rule',
                  'value',
                  'callback',
                  'userInfo', // 声明 userInfo 参数
                  val.validator
                    .replace(/^function\s*\(.*?\)\s*{/, '')
                    .replace(/}$/, '')
                )
                // 绑定 userInfo 到函数
                val.validator = (rule, value, callback) =>
                  func(rule, value, callback, userInfo)
              } catch (error) {
                console.error('解析 validator 失败:', error)
              }
            }
          })
        }
      })
      return jsonData
    },

    // 动态添加部门和人员选择字段
    async addDepartmentAndPersonnelFields() {
      await this.fetchDepartmentData()

      // 初始添加第一个主送组
      this.mainGroupCount = 1
      const mainGroupFields = this.generateMainGroupFields()

      // 抄送部门
      const notifiedField = {
        type: 'select',
        field: 'notifiedDepartment',
        title: '抄送部门',
        options: this.departmentList,
        props: {
          placeholder: '请选择抄送部门',
          multiple: true,
        },
        validate: [{ required: false, message: '请选择抄送部门' }],
        on: {
          change: this.onNotifiedChange,
        },
      }

      // 抄送人员
      const notifiedPersonnelField = {
        type: 'select',
        field: 'notifiedPerson',
        title: '抄送人员',
        options: [],
        props: {
          placeholder: '请选择抄送人员',
          multiple: true,
        },
        validate: [{ required: false, message: '请选择抄送人员' }],
      }

      // 添加按钮
      const addButton = {
        type: 'button',
        title: ' ',
        field: 'addButton',
        props: {
          type: 'primary',
          icon: 'el-icon-plus',
        },
        children: ['添加主送组'], // 添加按钮文本
        on: {
          click: this.addMainGroup,
        },
        // 包裹按钮的容器
        containerStyle: {
          display: 'flex',
          justifyContent: 'center', // 水平居中
          alignItems: 'center', // 垂直居中
          width: '100%', // 容器宽度占满父元素
        },
      }

      // 更新规则数组
      this.rankRule = [
        ...this.rankRule,
        ...mainGroupFields,
        addButton,
        notifiedField,
        notifiedPersonnelField,
      ]

      this.$forceUpdate()
    },

    // 添加主送组
    addMainGroup() {
      this.mainGroupCount += 1

      this.rankRule = [
        ...this.rankRule.filter(
          (field) =>
            field.field === 'file' || field.field === 'fileUploadDescription'
        ),
        ...this.generateMainGroupFields(),
        ...this.rankRule.filter((field) => field.field === 'addButton'),
        ...this.rankRule.filter(
          (field) =>
            field.field === 'notifiedDepartment' ||
            field.field === 'notifiedPerson'
        ),
      ]

      this.$forceUpdate()
    },

    // 生成主送组字段
    generateMainGroupFields() {
      const fields = []
      for (let i = 1; i <= this.mainGroupCount; i++) {
        // 如果当前下标存在于remoreIndex则跳过
        if (this.remoreIndex.includes(i - 1)) {
          continue // 跳过当前循环
        }

        // 根据 i 的值设置 children 内容
        const childrenContent =
          i === 1 ? [`主送组${i}`] : [`主送组${i}(点击删除该主送组)`]

        fields.push(
          // 主送组标题
          {
            type: 'vanTag',
            field: `departmentTitle_${i}`,
            children: childrenContent,
            display: true,
            hidden: false,
            props: {
              type: 'primary',
              size: 'large',
            },
            on: {
              click: () => this.deleteMasterGroup(i - 1),
            },
          },
          // 主送部门
          {
            type: 'select',
            field: `department_${i}`,
            title: '主送部门',
            value: this.departmentOptions[i - 1] || [], // 设置默认选中值
            options: this.departmentList,
            props: {
              placeholder: '请选择主送部门',
              multiple: true,
            },
            validate: [{ required: true, message: '请选择主送部门' }],
            on: {
              change: (value) => this.onDepartmentChange(value, i - 1),
            },
          },
          // 主送人员
          {
            type: 'select',
            field: `personnel_${i}`,
            title: '主送人员',
            value: this.selectPersonnelOptions[i - 1] || [], // 设置默认选中值
            options: this.personnelOptions[i - 1] || [],
            props: {
              placeholder: '请选择主送人员',
              multiple: true,
            },
            validate: [{ required: true, message: '请选择主送人员' }],
            on: {
              change: (value) => this.onPersonnelChange(value, i - 1),
            },
          }
        )
      }
      return fields
    },

    // 添加 文件上传的组件
    async addFileUploadField() {
      let token = window.localStorage.getItem('token') || ''

      const fileUploadField = {
        type: 'upload',
        field: 'file', // 字段名
        title: '附件上传',
        props: {
          multiple: false, // 是否支持多文件上传
          maxSize: 30 * 1024 * 1024, // 最大文件大小 (30MB)
          previewImage: true, // 是否预览
          beforeRemove: (file, fileList) => {
            // 获取当前文件在 fileList 中的下标
            let index = fileList.findIndex((f) => f.uid === file.uid) // 假设你用 uid 作为标识符

            // 获取对应的文件名
            let fileName = file.name

            return new Promise((resolve, reject) => {
              MessageBox.confirm(
                `确定要删除文件 "${fileName}" 吗？`,
                '确认删除',
                {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                }
              )
                .then(() => {
                  api.deleteFileById(this.fileIdList[index])
                  this.fileIdList.splice(index, 1) // 删除对应的文件ID
                  resolve() // 返回 true，表示删除文件
                })
                .catch(() => {
                  // 用户点击取消，不删
                  reject() // 返回 false，表示取消删除
                })
            })
          },

          name: 'file', // 后端接收的字段名

          // accept: '.pdf,.doc,.docx,.xlsx,.txt,.png,.jpg,.ppt,.pptx',
          accept: '*/*',
          // 移动端适配
          capture: null,
          webkitdirectory: false,

          action: '/prod-api/admin/upload/img', // 上传接口
          onSuccess: (response, file, fileList) => {
            if (response.code === 200) {
              // 提取返回的数据
              const fileId = response.data.id
              // 将文件ID和文件名添加到对应的列表中
              this.fileIdList.push(fileId)
              this.$message.success(`文件上传成功`)
            } else {
              this.$message.error('文件上传失败，请重试！')
            }
          },
          headers: {
            Token: `${token}`, // 如果需要认证，传递 Token
          },
        },
        validate: [{ required: false, message: '请上传文件' }],
      }

      const fileUploadDescription = {
        type: 'vanNoticeBar', // 定义为文本组件
        field: 'fileUploadDescription', // 确保唯一性
        props: {
          text: '上传类型：ppt、work文档、excel、图片，上传大小限制30MB',
        },
      }

      // 将上传字段和说明文本一起加入 rankRule
      this.rankRule.push(fileUploadDescription)

      this.rankRule.push(fileUploadField)
    },

    // 获取部门数据
    async fetchDepartmentData() {
      try {
        const response = await api.getDepartmentList()
        this.departmentList = response.data.map((item) => ({
          label: item.name,
          value: item.id,
        }))
        console.log('部门列表:', this.departmentList) // 打印检查数据格式
      } catch (error) {
        console.error('部门数据加载失败:', error)
      }
    },

    // 新增方法：获取部门对应人员
    async fetchPersonnelData(departmentIds) {
      if (departmentIds.length > 0) {
        try {
          const response = await api.selectedDepartments(departmentIds)
          // 打印响应，查看数据结构
          console.log('API响应:', response)

          // 返回格式化的数据
          return response.data.map((item) => ({
            label: item.name,
            value: item.username,
          }))
        } catch (error) {
          console.error('异常：', error)
          return []
        }
      } else {
        return []
      }
    },

    // 修改后的部门change处理 groupIndex是下标的意思
    async onDepartmentChange(value, groupIndex) {
      // 过滤无效值
      const validDepartments = value.filter(Boolean)
      if (validDepartments.length === 0) {
        this.personnelOptions[groupIndex] = []
        this.updatePersonnelField(groupIndex)
        return
      }

      // 添加主送部门的选中值
      this.departmentOptions = {
        ...this.departmentOptions,
        [groupIndex]: value,
      }

      console.log('选中的部门', this.departmentOptions)

      // 获取对应部门的人员数据
      const personnel = await this.fetchPersonnelData(validDepartments)

      // 更新人员选项
      this.personnelOptions = {
        ...this.personnelOptions,
        [groupIndex]: personnel,
      }

      console.log(
        '查询到的主送人员:',
        this.personnelOptions,
        '当前下表:',
        groupIndex
      )

      this.updatePersonnelField(groupIndex)
    },

    // 修改后的人员change处理 groupIndex是下标的意思
    async onPersonnelChange(value, groupIndex) {
      // 过滤无效值
      const validPersonnels = value.filter(Boolean)
      if (validPersonnels.length === 0) {
        this.selectPersonnelOptions[groupIndex] = []
        return
      }

      // 添加主送人员的选中值
      this.selectPersonnelOptions = {
        ...this.selectPersonnelOptions,
        [groupIndex]: value,
      }

      console.log('查看选中的主送人员:', this.selectPersonnelOptions)
    },

    // 删除对应主送组
    deleteMasterGroup(groupIndex) {
      // 如果下标是0则禁止删除
      if (groupIndex == 0) {
        this.$message.warning(`最初主送组禁止删除`)
        return
      }
      // 下标不是0则询问删除
      // 弹出确认框，询问是否删除
      this.$confirm('确定删除该主送组吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 删除对应数据
          delete this.personnelOptions[groupIndex]
          delete this.selectPersonnelOptions[groupIndex]
          delete this.departmentOptions[groupIndex]
          this.remoreIndex.push(groupIndex)

          // 重新生成主送组
          this.rankRule = [
            ...this.rankRule.filter(
              (field) =>
                field.field === 'file' ||
                field.field === 'fileUploadDescription'
            ),
            ...this.generateMainGroupFields(),
            ...this.rankRule.filter((field) => field.field === 'addButton'),
            ...this.rankRule.filter(
              (field) =>
                field.field === 'notifiedDepartment' ||
                field.field === 'notifiedPerson'
            ),
          ]
          this.$forceUpdate()

          // 确认删除
          this.$message.success('主送组已删除')
        })
        .catch(() => {
          // 取消删除
          this.$message.info('删除操作已取消')
        })
    },

    // 更新对应人员字段的公共方法
    updatePersonnelField(groupIndex) {
      // 下表是0 但是显示是+1的
      let gindex = groupIndex + 1
      this.rankRule = this.rankRule.map((field) => {
        if (field.field === `personnel_${gindex}`) {
          return {
            ...field,
            options: this.personnelOptions[groupIndex] || [],
          }
        }
        return field
      })
      this.$forceUpdate()
    },
    // 根据选中的抄送部门获取对应的人员数据
    onNotifiedChange(selectedDepartments) {
      if (selectedDepartments.length > 0) {
        api.selectedDepartments(selectedDepartments).then((response) => {
          this.notifiedPersonList = response.data.map((item) => ({
            label: item.name,
            value: item.username,
          }))

          // 更新人员选择框的 options
          const personnelField = this.rankRule.find(
            (item) => item.field === 'notifiedPerson'
          )
          if (personnelField) {
            personnelField.options = this.notifiedPersonList
          }
        })
      }
    },
    // 提交
    onSubmit(formData) {
      console.log('formData:', formData)
      console.log('rule', this.rule)
      console.log('rankRule', this.rankRule)
      // 显示数据
      let formShowData = {}
      // 真实数据
      let newFormData = {}

      // 选中的人员
      let selectedPersonnel = []

      // 添加显示数据
      this.rule.forEach((item) => {
        for (let key in formData) {
          if (key === item.field) {
            formShowData[item.title] = formData[key]
            newFormData[item.field] = formData[key]
          }
        }
      })

      console.log('newFormData:', newFormData)

      // 主送人员
      // this.rankRule.forEach((item) => {
      //   for (let key in formData) {
      //     if (key === 'personnel') {
      //       formData[key].forEach((id) => {
      //         if (!selectedPersonnel.includes(id)) {
      //           selectedPersonnel.push(id) // 直接添加 value
      //         }
      //       })
      //     }
      //   }
      // })

      // 转换为二维数组，只保留 value 的值
      selectedPersonnel = Object.values(this.selectPersonnelOptions).map(
        (item) => item
      )
      console.log('selectedPersonnel:', selectedPersonnel)

      // 进行抄送
      let selectedNotified = []
      this.rankRule.forEach((item) => {
        for (let key in formData) {
          if (key === 'notifiedPerson') {
            formData[key].forEach((id) => {
              if (!selectedNotified.includes(id)) {
                selectedNotified.push(id) // 直接添加 value
              }
            })
          }
        }
      })

      let DATA = {
        formData: newFormData,
        formShowData: formShowData,
      }
      console.log('data:', DATA)

      let processFormVo = {
        processTemplateId: this.processTemplate.id,
        processTypeId: this.processTemplate.processTypeId,
        formValues: JSON.stringify(DATA),
        approvalNameList: selectedPersonnel,
        templateType: this.processTemplate.templateType,
        fileIds: this.fileIdList,
        notifiedPersonList: selectedNotified,
      }

      console.log('提交的数据 processFormVo:', processFormVo)
      api.startUp(processFormVo).then((response) => {
        //调整到已发起列表
        this.$router.push({ path: '/list/2' })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-form {
  .el-form-item {
    /deep/ .el-form-item__label {
      font-size: 18px;
      font-weight: 800;
      color: blue;
    }
  }
}

.group-title {
  font-size: 16px;
  font-weight: bold;
  color: #606266;
  margin: 12px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #ebeef5;
}
</style>
